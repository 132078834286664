import { Drawer } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import "./dropdownMenu.css";

const DropdownMenu = ({
  data,
  list,
  setList,
  isDropdown,
  setIsDropdown,
  year,
}) => {
  const handleCloseDropDown = () => {
    setIsDropdown(false);
  };

  const options = data
    .filter((it) => it.dcr?.[year] || it.pcr?.[year] || it.tran?.[year])
    .map((option) => (
      <option className="dropdown-option" key={option.id} value={option.id}>
        {option.properties.NAME}
      </option>
    ));

  const addSelectedItem = (e) => {
    if (e.target.value !== "undefined") {
      setList([...list, parseInt(e.target.value)]);
    }
  };

  const deleteItem = (index) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  };

  return (
    <Drawer
      open={isDropdown}
      anchor="right"
      variant="persistent"
      onClose={handleCloseDropDown}
      classes={{ paper: "drawer-paper" }}
    >
      {list.length < 5 && (
        <div className="dropdown-header">
          <div className="dropdown-header-text">
            <label className="dropdown-label">close the slide</label>
            <button className="dropdown-arrow" onClick={handleCloseDropDown}>
              &rarr;
            </button>
          </div>
          <select className="dropdown-select" onChange={addSelectedItem}>
            <option value={"undefined"}>Select country</option>
            {options}
          </select>
        </div>
      )}
      {list.map((id, index) => {
        const country = data.find((it) => it.id === id);
        return (
          <div className="dropdown-box" key={country.id}>
            <div className="dropdown-box-header">
              <p>{country.properties.NAME}</p>
              <p>Declared</p>
              <p>Perceived</p>
              <button
                className="dropdown-icon"
                onClick={() => deleteItem(index)}
              >
                X
              </button>
            </div>
            <div className="dropdown-box-description">
              <p>Score:</p>
              <p>{country.dcr?.[year] ? country.dcr[year].Score : "-"}</p>
              <p>{country.pcr?.[year] ? country.pcr[year].Score : "-"}</p>
            </div>
            <div className="dropdown-box-description-last">
              <p>Transparency:</p>
              <p>
                {country.tran?.[year] ? country.tran[year].label : "-"}
              </p>

            </div>
          </div>
        );
      })}
      {list.length === 0 ? (
        <p className="dropdown-link-text">
          Select up to 5 countries to compare
        </p>
      ) : (
        <Link className="dropdown-link" to="/selected" state={list}>
          Show details
        </Link>
      )}
    </Drawer>
  );
};

export default DropdownMenu;
