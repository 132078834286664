import React from "react";
import { MapMode } from "../../../../../src/model/mapMode.ts";
import { colorRange } from "../../mapUtils.js";
import "./countryLegend.css";

const CountryLegend = ({ mode, year, setSelectedLegend, selectedLegend }) => {
  if (mode === MapMode.MODE_DECLARED) {
    return (
      <div className="legend">
        <h4>Select cluster to highlight</h4>
        <div>{""} 0 </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.zero,
              display: "inline-block",
              border: selectedLegend === 0 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(0)}
          ></span>
          <div className="hide">
            No indications
          </div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.seven,
              display: "inline-block",
              border: selectedLegend === 1 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(1)}
          ></span>
          <div className="hide">
            Aspirations
          </div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.eight,
              display: "inline-block",
              border: selectedLegend === 2 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(2)}
          ></span>
          <div className="hide">Emerging capabilities</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.nine,
              display: "inline-block",
              border: selectedLegend === 3 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(3)}
          ></span>
          <div className="hide">Low tactical effects</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.ten,
              display: "inline-block",
              border: selectedLegend === 4 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(4)}
          ></span>
          <div className="hide">High tactical effects</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.eleven,
              display: "inline-block",
              border: selectedLegend === 5 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(5)}
          ></span>
          <div className="hide">Low strategic effects</div>
        </div>
          <div className="legend-map">
            <span
              style={{
                height: "1rem",
                width: "1rem",
                background: colorRange.twelve,
                display: "inline-block",
                border: selectedLegend === 6 && "2px solid orange",
                cursor: "pointer",
              }}
              onClick={() => setSelectedLegend(6)}
            ></span>

            <div className="hide">High strategic effects</div>
          </div>
        <div>6</div>
      </div>
    );
  } else if (mode === MapMode.MODE_PERCEIVED) {
    return (
      <div className="legend">
        <h4>Select cluster to highlight</h4>
        <div>0</div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.zero,
              display: "inline-block",
              border: selectedLegend === 0 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(0)}
          ></span>
          <div className="hide">
            No indications
          </div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.one,
              display: "inline-block",
              border: selectedLegend === 1 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(1)}
          ></span>
          <div className="hide">
            Aspirations
          </div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.two,
              display: "inline-block",
              border: selectedLegend === 2 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(2)}
          ></span>
          <div className="hide">Emerging capabilities</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.three,
              display: "inline-block",
              border: selectedLegend === 3 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(3)}
          ></span>{" "}
          <div className="hide">Low tactical effects</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.four,
              display: "inline-block",
              border: selectedLegend === 4 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(4)}
          ></span>
          <div className="hide">High tactical effects</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.five,
              display: "inline-block",
              border: selectedLegend === 5 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(5)}
          ></span>
          <div className="hide">Low strategic effects</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.six,
              display: "inline-block",
              border: selectedLegend === 6 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(6)}
          ></span>
          <div className="hide">High strategic effects</div>
        </div>
        <div>6</div>
      </div>
    );
  } else if (mode === MapMode.MODE_TRANSPARENCY) {
    return (
      <div className="legend">
        <h4>Select cluster to highlight</h4>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.red,
              display: "inline-block",
              border: selectedLegend === 0 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(0)}
          ></span>
          <div className="hide">Very untransparent</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.darkYellow,
              display: "inline-block",
              border: selectedLegend === 1 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(1)}
          ></span>
          <div className="hide">Untransparent</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.yellow,
              display: "inline-block",
              border: selectedLegend === 2 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(2)}
          ></span>
          <div className="hide">Somewhat transparent and low capability</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.lightYellow,
              display: "inline-block",
              border: selectedLegend === 4 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(4)}
          ></span>
          <div className="hide">Somewhat transparent and high capability</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.darkGreen,
              display: "inline-block",
              border: selectedLegend === 3 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(3)}
          ></span>
          <div className="hide">Higher declared capability</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.lightGreen,
              display: "inline-block",
              border: selectedLegend === 5 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(5)}
          ></span>
          <div className="hide">Transparent and low capability</div>
        </div>
        <div className="legend-map">
          <span
            style={{
              height: "1rem",
              width: "1rem",
              background: colorRange.lightBlue,
              display: "inline-block",
              border: selectedLegend === 6 && "2px solid orange",
              cursor: "pointer",
            }}
            onClick={() => setSelectedLegend(6)}
          ></span>
          <div className="hide">Transparent and high capability</div>
        </div>
      </div>
    );
  }
};

export default CountryLegend;
