import React, { useState, useEffect } from "react";
import "./average.css";
import Card from "@mui/material/Card";
import data from "../../../../data/datawithtotallinks.json";

const Average = ({ currentYear }) => {
  function avgCountry(countries, continent) {
    let pcrSum = 0,
      dcrSum = 0,
      pcrCount = 0,
      dcrCount = 0;

    countries.forEach((country) => {
      if (
        country.dcr?.[currentYear] &&
        country.dcr[currentYear].Score !== undefined
      ) {
        dcrSum += country.dcr[currentYear].Score;
        dcrCount++;
      }
      if (
        country.pcr?.[currentYear] &&
        country.pcr[currentYear].Score !== undefined
      ) {
        pcrSum += country.pcr[currentYear].Score;
        pcrCount++;
      }
    });
    return {
      pcr: pcrCount ? Math.floor((pcrSum / pcrCount) * 100) / 100 : 0,
      dcr: dcrCount ? Math.floor((dcrSum / dcrCount) * 100) / 100 : 0,
    };
  }
  const [avg, setAvg] = useState({
    asia: { pcr: 0, dcr: 0 },
    africa: { pcr: 0, dcr: 0 },
    europe: { pcr: 0, dcr: 0 },
    northAmerica: { pcr: 0, dcr: 0 },
    southAmerica: { pcr: 0, dcr: 0 },
    oceania: { pcr: 0, dcr: 0 },
  });

  useEffect(() => {
    let NAmericaAvg = avgCountry(
      data.filter((item) => item.properties.continent === "North America")
    );
    let SAmericaAvg = avgCountry(
      data.filter((item) => item.properties.continent === "South America")
    );
    let AsiaAvg = avgCountry(
      data.filter((item) => item.properties.continent === "Asia"),
      "asia"
    );
    let AfricaAvg = avgCountry(
      data.filter((item) => item.properties.continent === "Africa")
    );
    let EuropeAvg = avgCountry(
      data.filter((item) => item.properties.continent === "Europe")
    );

    let OceaniaAvg = avgCountry(
      data.filter((item) => item.properties.continent === "Oceania")
    );

    setAvg({
      asia: AsiaAvg,
      southAmerica: SAmericaAvg,
      northAmerica: NAmericaAvg,
      europe: EuropeAvg,
      africa: AfricaAvg,
      oceania: OceaniaAvg,
    });
  }, [currentYear]);

  return (
    <Card sx={{ minWidth: 275, overflowY: "auto" }}>
      <p className="avg-description">
        Compare the average scores for each continent
      </p>
      <table className="avg-table">
        <thead>
          <tr className="avg-header">
            <th>Continent</th>
            <th>Declared</th>
            <th>Perceived</th>
          </tr>
        </thead>
        <tbody>
          <tr className="avg-rows">
            <td className="avg-cell">Europe</td>
            <td>{avg.europe.dcr}</td>
            <td>{avg.europe.pcr}</td>
          </tr>
          <tr className="avg-rows">
            <td className="avg-cell">Asia</td>
            <td>{avg.asia.dcr}</td>
            <td>{avg.asia.pcr}</td>
          </tr>
          <tr className="avg-rows">
            <td className="avg-cell">Africa</td>
            <td>{avg.africa.dcr}</td>
            <td>{avg.africa.pcr}</td>
          </tr>
          <tr className="avg-rows">
            <td className="avg-cell">North America</td>
            <td>{avg.northAmerica.dcr}</td>
            <td>{avg.northAmerica.pcr}</td>
          </tr>
          <tr className="avg-rows">
            <td className="avg-cell">South America</td>
            <td>{avg.southAmerica.dcr}</td>
            <td>{avg.southAmerica.pcr}</td>
          </tr>
          <tr className="avg-rows">
            <td className="avg-cell">Oceania</td>
            <td>{avg.oceania.dcr}</td>
            <td>{avg.oceania.pcr}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default Average;
