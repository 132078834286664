import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../../data/datawithtotallinks.json";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@material-ui/core/Grid";
import "./pastData.css";

const PastData = () => {
  const { country } = useParams();
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(() => {
    const find = data.find((item) => item.id === parseInt(country));
    setSelectedCountry(find);
  }, [country]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div className="pastData-container">
      <div className="pastData-header">
        <p className="pastData-name">{selectedCountry?.properties.NAME} </p>
        <a href="/" className="pastData-link">
          Go Back
        </a>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Item>
            <p className="pastData-date">2022</p>
            <h3 className="heading-tertiary">Declared Capabilities</h3>
            <p>
              {" "}
              {selectedCountry?.tran === undefined ? (
                <p>There is no Transparency for this country.</p>
              ) : (
                <p >
                  Transparency: {selectedCountry?.tran[2022]?.label}
                </p>
              )}{" "}
            </p>
            <div className="pastData-description">
              {" "}
              <p>
                {" "}
                <span>Score: </span>
                {selectedCountry?.dcr[2022]?.Score}
              </p>
              <p>
                <span>Level: </span>
                {selectedCountry?.dcr[2022]?.Level}
              </p>
              <p>
                <span>Freq: </span>
                {selectedCountry?.dcr[2022]?.Freq}
              </p>
              <p>
                <span>Rank: </span>
                {selectedCountry?.dcr[2022]?.Rank}
              </p>
              <p>
                <span>Number of documents: </span>
                {selectedCountry?.dcr[2022]?.documents.length}
              </p>
            </div>
            <p className="pastData-text">
              {selectedCountry?.dcr[2022]?.GeneralTrends}
            </p>
          </Item>
          <Item>
            <h3 className="heading-tertiary">Perceived Capabilities</h3>
            <div className="pastData-description">
              {" "}
              <p>
                {" "}
                <span>Score: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Score
                )}
              </p>
              <p>
                <span>Level: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Level
                )}
              </p>
              <p>
                <span>Freq: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Freq
                )}
              </p>
              <p>
                <span>Rank: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Rank
                )}
              </p>
              <p>
                <span>Number of documents: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.documents.length
                )}
              </p>
            </div>
            <p className="pastData-text">
              {selectedCountry?.pcr === undefined ? (
                <p>No Perceived data</p>
              ) : (
                selectedCountry?.pcr[2022]?.GeneralTrends
              )}
            </p>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Item>
            <p className="pastData-date">2023</p>
            <h3 className="heading-tertiary">Declared Capabilities</h3>
            <p>
              {" "}
              {selectedCountry?.tran === undefined ? (
                <p>There is no Transparency for this country.</p>
              ) : (
                selectedCountry?.tran[2022]?.label
              )}{" "}
            </p>
            <div className="pastData-description">
              {" "}
              <p>
                {" "}
                <span>Score: </span>
                {selectedCountry?.dcr[2022]?.Score}
              </p>
              <p>
                <span>Level: </span>
                {selectedCountry?.dcr[2022]?.Level}
              </p>
              <p>
                <span>Freq: </span>
                {selectedCountry?.dcr[2022]?.Freq}
              </p>
              <p>
                <span>Rank: </span>
                {selectedCountry?.dcr[2022]?.Rank}
              </p>
              <p>
                <span>Number of documents: </span>
                {selectedCountry?.dcr[2022]?.documents.length}
              </p>
            </div>
            <p className="pastData-text">
              {selectedCountry?.dcr[2022]?.GeneralTrends}
            </p>
          </Item>
          <Item>
            <h3 className="heading-tertiary">Perceived Capabilities</h3>
            <div className="pastData-description">
              {" "}
              <p>
                <span>Score: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Score
                )}
              </p>
              <p>
                <span>Level: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Level
                )}
              </p>
              <p>
                <span>Freq: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Freq
                )}
              </p>
              <p>
                <span>Rank: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.Rank
                )}
              </p>
              <p>
                <span>Number of documents: </span>
                {selectedCountry?.pcr === undefined ? (
                  <p>No Perceived data</p>
                ) : (
                  selectedCountry?.pcr[2022]?.documents.length
                )}
              </p>
            </div>
            <p className="pastData-text">
              {selectedCountry?.pcr === undefined ? (
                <p>No Perceived data</p>
              ) : (
                selectedCountry?.pcr[2022]?.GeneralTrends
              )}
            </p>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default PastData;
