import React from "react";
import "./transparentContainer.css";
import Logo from "../logo/Logo";

const TransparentContainer = ({ showComponent, setShowComponent }) => {
  const handleClick = () => {
    setShowComponent(false);
  };

  return (
    <div className="transparent-container">
      <div className="transparent-box">
        <h3 className="transparent-primary">
          The Cyber Arms Watch offers a transparency index on the offensive
          cyber capabilities of states. It compares the degree to which states
          are transparent about their declared cyber capabilities to outside
          perceptions of those capabilities.
        </h3>
        <ul className="transparent-list">
          <li>
            <h5 className="transparent-secondary">
              Declared Capabilities Rating (DCR)
            </h5>
            <p className="transparent-description">
              Indicates to what extent a state publicly discloses information
              about its offensive cyber capabilities. This includes official
              communication by the respective government, such as strategies,
              doctrines, and similar documents, as well as sanctioned media
              reporting that cumulatively indicate the level of declared
              capability using a seven-tiered labelling system. The
              classification ranges from no official indications of offensive
              cyber capabilities, to stated aspirations, disclosed tactical
              effects (low and high) and declared strategic effects (low and
              high).
            </p>
          </li>
          <li>
            <h5 className="transparent-secondary">
              Perceived Capabilities Rating (PCR)
            </h5>
            <p className="transparent-description">
              Indicates the perceived offensive cyber capabilities of a state
              using open-source information and categorizes them using a similar
              seven-level categorization system. Whereas the DCR is limited to
              official disclosures by the respective government itself, the PCR
              uses external sources to show how their offensive cyber
              capabilities are observed by outsiders. This includes sources such
              as intelligence reports and assessments from other governments or
              non-state actors, indictments, sanctions, past operations, leaked
              documents.
            </p>
            <div className="transparent-btn" onClick={handleClick}>
              To the monitor
            </div>
          </li>
          <li>
            <h5 className="transparent-secondary">Transparency Score</h5>
            <p className="transparent-description">
              The combination of the DCR and PCR result in a transparency score
              per country. This score reflects both a state's overall offensive
              cyber capabilities, as well as the extent to which said state is
              transparent about such capabilities.
            </p>
          </li>
        </ul>
      </div>
      <div>
        <Logo />
      </div>
    </div>
  );
};

export default TransparentContainer;
