import React, { useState } from "react";
import { MapMode } from "../../model/mapMode.ts";
import "./lollipopChart.css";

const tranScoreOrder = [
  "Transparent and High Capability",
  "Transparent and Low Capability",
  "Higher Declared Capability",
  "Somewhat Transparent and High Capability",
  "Somewhat Transparent and Low Capability",
  "Untransparent",
  "Very Untransparent",
];

const LollipopChart = ({ mode, lollipopChartData }) => {
  const [sortBy, setSortBy] = useState("Score");
  const [sortOrder, setSortOrder] = useState("desc");
  const superPower = [],
    middlePower = [];

  const filterData = lollipopChartData.sort((sortkeyone, sortkeytwo) => {
    let a = "";
    let b = "";

    if (sortOrder === "desc") {
      a = sortkeytwo;
      b = sortkeyone;
    } else {
      a = sortkeyone;
      b = sortkeytwo;
    }
    if (sortBy === "Name") {
      return a.Country.localeCompare(b.Country);
    } else if (sortBy === "ScoreDcr") {
      return a.ScoreDcr - b.ScoreDcr;
    } else if (sortBy === "ScorePcr") {
      return a.ScorePcr - b.ScorePcr;
    } else if (sortBy === "Score") {
      if (typeof a.Score === "string") {
        const aIndex = tranScoreOrder.indexOf(a.Score);
        const bIndex = tranScoreOrder.indexOf(b.Score);
        return bIndex - aIndex;
      } else return a.Score - b.Score;
    }
  });
  // length of the lollipops
  let maxScore = 0;
  filterData.forEach((item) => {
    maxScore = Math.max(maxScore, item.Score || 0);
  });

  filterData.forEach((item, index) => {
    if (item.Score === 6) superPower.push(item);
    else middlePower.push(item);
  });

  const sortByCountry = () => {
    if (sortBy === "Name") setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    else setSortBy("Name");
  };

  const sortByScore = () => {
    if (sortBy === "Score") setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    setSortBy("Score");
  };

  const sortByScoreDcr = () => {
    if (sortBy === "ScoreDcr")
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    setSortBy("ScoreDcr");
  };

  const sortByScorePcr = () => {
    if (sortBy === "ScorePcr")
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    setSortBy("ScorePcr");
  };

  const renderList = (list) => {
    return list.map((item, index) => {
      const score = item.Score;
      if (item.Score === undefined) {
        return item.Score === "-";
      }
      return (
        <div className="lolli-table-row" key={item.Country}>
          <p className="lolli-table-description">{item.Country}</p>
          <div className="lolli-table-description lolli-table-last-row">
            <span
              className="lolli-score-bar"
              style={{ width: `${(score / maxScore) * 100}%` }}
            ></span>
            <span className="lolli-score-bg"></span>
            <span
              className="lolli-score-circle"
              style={{ left: `${(score / maxScore) * 100}%` }}
            >
              <span className="lolli-score-number">
                {mode === MapMode.MODE_DECLARED && item.Score + "/6"}
                {mode === MapMode.MODE_PERCEIVED && item.Score + "/6"}
              </span>
            </span>
          </div>
        </div>
      );
    });
  };
  const renderTranList = (list) => {
    return list.map((item, index) => {
      if (item.Score === undefined) {
        return item.Score === "-";
      }

      return (
        <div
          className="lolli-table-row lolli-table-row-four"
          key={item.Country}
        >
          <p className="lolli-table-description">{item.Country}</p>
          <div className="lolli-table-description">
            <span>{item.Score}</span>
          </div>
          <div className="lolli-table-description lolli-table-last-row">
            <span>{item.ScoreDcr}</span>
          </div>
          <div className="lolli-table-description lolli-table-last-row">
            <span>{item.ScorePcr}</span>
          </div>
        </div>
      );
    });
  };

  if (mode === MapMode.MODE_TRANSPARENCY) {
    return (
      <div className="lolli-container">
        <div className="lolli-table lolli-table-row-four">
          <div className="lolli-table-header" onClick={sortByCountry}>
            Country
          </div>
          <div className="lolli-table-header" onClick={sortByScore}>
            Score
          </div>
          <div
            className="lolli-table-header lolli-table-header-text"
            onClick={sortByScoreDcr}
          >
            Declared score
          </div>
          <div
            className="lolli-table-header lolli-table-header-text"
            onClick={sortByScorePcr}
          >
            Perceived score
          </div>
        </div>
        {renderTranList(filterData)}
      </div>
    );
  }

  return (
    <div className="lolli-container">
      <div className="lolli-table ">
        <div className="lolli-table-header" onClick={sortByCountry}>
          Country
        </div>
        <div className="lolli-table-header" onClick={sortByScore}>
          Score
        </div>
      </div>
      {sortBy === "Score" ? (
        <>
          {sortOrder === "desc" ? (
            <>
              <p>HIGHEST CAPABILITIES</p>
              {renderList(superPower)}
              <p>OTHER COUNTRIES CAPABILITIES</p>
              {renderList(middlePower)}
            </>
          ) : (
            <>
              <p>OTHER COUNTRIES CAPABILITIES</p>
              {renderList(middlePower)}
              <p>HIGHEST CAPABILITIES</p>
              {renderList(superPower)}
            </>
          )}
        </>
      ) : (
        renderList(filterData)
      )}
    </div>
  );
};

export default LollipopChart;
