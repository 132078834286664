import React from "react";
import "./App.css";
import esriConfig from "@arcgis/core/config.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Countries from "./pages/countries/Countries";
import CountryDetails from "./pages/countryDetail/CountryDetails";
import CountrySelected from "./pages/countrySelected/CountrySelected";
import PastData from "./pages/pastData/PastData";


esriConfig.assetsPath = "./assets";

const App = () => {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Countries />} />
        <Route path="/detail/:country" element={<CountryDetails />} />
        <Route path="/selected" element={<CountrySelected />} />
        <Route path="/pastData/:country" element={<PastData />}></Route>
      </Routes>

    </BrowserRouter>
  );
};

export default App;
