import React from "react";
import { useLocation } from "react-router-dom";
import data from "../../data/datawithtotallinks.json";
import "./countrySelected.css";

const currentYear = 2022;

const CountrySelected = () => {
  const location = useLocation();
  const { state: list } = location;

  return (
    <section className="country-selected-container">
      <div className="country-selected-header-container">
        <h2 className="heading-secondary country-selected-header">
          Selected Countries
        </h2>
        <a href="/" className="country-selected-link">
          Go Back
        </a>
      </div>
      <div className="country-selected-container-box">
        {list.map((item) => {
          const country = data.find((it) => it.id === item);
          return (
            <div className="country-selected-box" key={item}>
              <h3 className="country-selected-box-header">
                {country.properties.NAME}
              </h3>
              <div className="country-selected-box-content">
                <div>
                  <div className="country-selected-box-content-text">
                    Declared Capabilities
                  </div>
                  <div className="country-selected-box-content-number">
                    <span className="country-selected-box-content-span">
                      Score:{" "}
                    </span>
                    <p> {country.dcr[currentYear].Score ?? "-"}</p>
                  </div>
                  <div className="country-selected-box-content-number">
                    <span className="country-selected-box-content-span">
                      Document Frequency:{" "}
                    </span>
                    <p>{country.dcr[currentYear].documents.length ?? "-"}</p>
                  </div>
                  <div className="country-selected-box-content-number">
                    <span className="country-selected-box-content-span">
                      Rank Frequency: {" "}
                    </span>
                    <p>{country.dcr[currentYear].Rank ?? "-"}</p>
                  </div>
                  <div>
                    <span className="country-selected-box-content-span">
                      General Trends: {" "}
                    </span>
                    <p>{country.dcr[currentYear].GeneralTrends ?? "-"}</p>
                  </div>
                </div>
                <div>
                  <p className="country-selected-box-content-text">
                    Perceived Capabilities
                  </p>
                  <div className="country-selected-box-content-p">
                    {country.pcr === undefined ? (
                      <p>There is no perceived data for this country.</p>
                    ) : (
                      country.pcr[currentYear].Score ?? "-"
                    )}
                  </div>
                  <div className="country-selected-box-content-p">
                    {country.pcr === undefined ? (
                      <p></p>
                    ) : (
                      country.pcr[currentYear].documents.length ?? "-"
                    )}
                  </div>
                  <div className="country-selected-box-content-p">
                    {country.pcr === undefined ? (
                      <p></p>
                    ) : (
                      country.pcr[currentYear].Rank ?? "-"
                    )}
                  </div>
                  <div>
                    {country.pcr === undefined ? (
                      <span></span>
                    ) : (
                      <p className="country-selected-description">{country.pcr[currentYear].GeneralTrends ?? "-"}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CountrySelected;
