import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import data from "../../data/datawithtotallinks.json";
import { MapMode } from "../../model/mapMode.ts";
import "./countryDetails.css";

const currentYear = 2022
const CountryDetails = () => {
  const { country } = useParams();
  const [documents, setDocuments] = useState([]);
  const [score, setScore] = useState();
  const [mode, setMode] = useState();
  const location = useLocation();

  const sortDataByDate = () => {
    setDocuments([...documents.sort((a, b) => b.date - a.date)]);
  };

  const sortDataByDateOld = () => {
    setDocuments([...documents.sort((a, b) => a.date - b.date)]);
  };

  useEffect(() => {
    const countryDetail = data.find((item) => item.properties.NAME === country);

    if (countryDetail) {
      if (mode === MapMode.MODE_DECLARED && countryDetail.dcr?.[currentYear]) {
        setDocuments(countryDetail.dcr[currentYear].documents);
        setScore(countryDetail.dcr[currentYear].Score);
      } else if (
        mode === MapMode.MODE_PERCEIVED &&
        countryDetail.pcr?.[currentYear]
      ) {
        setDocuments(countryDetail.pcr[currentYear].documents);
        setScore(countryDetail.pcr[currentYear].Score);
      }
    } else setDocuments([]);
  }, [country, mode]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get("mode");
    setMode(mode);
  }, [location.search]);

  return (
    <div className="countryDetail">
      <header className="countryDetails-header">
        <h3>Country : {country}</h3>
        <p>Score : {score}</p>
        <a href="/" className="countryDetails-link">
          Go back
        </a>
      </header>
      <div className="country-container-details-btn">
        <button
          className={
            mode === MapMode.MODE_DECLARED
              ? "btn map-btn-details active"
              : "btn map-btn-details"
          }
          onClick={() => {
            setMode(MapMode.MODE_DECLARED);
          }}
        >
          Declared Capabilities
        </button>
        <button
          className={
            mode === MapMode.MODE_PERCEIVED
              ? "btn map-btn-details active"
              : "btn map-btn-details"
          }
          onClick={() => setMode(MapMode.MODE_PERCEIVED)}
        >
          Perceived Capabilities
        </button>
      </div>
      <table className="countryDetails-table-grid countryDetails-table">
        <thead className="countryDetails-table-grid">
          <tr className="countryDetails-table-row">
            <th className="countryDetails-table-th">Document</th>
            <th className="countryDetails-table-th">
              <div className="countryDetails-icon">
                <FaCaretUp className="arrow-top" onClick={sortDataByDate} />
                Date
                <FaCaretDown
                  className="arrow-down"
                  onClick={sortDataByDateOld}
                />
              </div>
            </th>
            <th className="countryDetails-table-th">Excerpt</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((item, index) => (
            <tr key={index}>
              <td>{item.document}</td>
              <td>{item.date}</td>
              <td>
                {item.excerpt}
                <div>
                  {" "}
                  {item.link ? (
                    <a
                      className="countryDetails-source"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Go to source
                    </a>
                  ) : (
                    <span>
                      There are no links or documents for this country.
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CountryDetails;
