import { MapMode } from "../../model/mapMode.ts";
import { CustomContent } from "@arcgis/core/popup/content";

export const colorRange = {
  zero: "#F6F1DB",
  one: "#E7CD9A",
  two: "#D79C5A",
  three: "#BE5E4C",
  four: "#A53F55",
  five: "#8B3266", 
  six: "#990033", /*"#E7E2F2"*/
  seven: "#C2C1E1",
  eight: "#A1ACCF",
  nine: "#82A0BC",
  ten: "#6BA3A9",
  eleven: "#569585",
  twelve: "#437F59",
  darkGreen: "#4B825F",
  lightGreen: "#4e8341",
  lightBlue: "#97AFC5",
  lightYellow: "#fff7aa",
  yellow: "#dbad50",
  darkYellow: "#CC9882",
  red: "#ae1917",
  gray: "#808080",
};

export function generateCountryColor(country, mode, list, year) {
  if (mode === MapMode.MODE_DECLARED) {
    if (country.dcr?.[year]) {
      if (country.dcr[year].Score === 0) return colorRange.zero;
      else if (country.dcr[year].Score === 1) return colorRange.seven;
      else if (country.dcr[year].Score === 2) return colorRange.eight;
      else if (country.dcr[year].Score === 3) return colorRange.nine;
      else if (country.dcr[year].Score === 4) return colorRange.ten;
      else if (country.dcr[year].Score === 5) return colorRange.eleven;
      else if (country.dcr[year].Score === 6) return colorRange.twelve;
    }
  } else if (mode === MapMode.MODE_PERCEIVED) {
    if (country.pcr?.[year]) {
      if (country.pcr[year].Score === 0) return colorRange.zero;
      else if (country.pcr[year].Country === "Portugal") return colorRange.zero;
      else if (country.pcr[year].Score === 1) return colorRange.one;
      else if (country.pcr[year].Score === 2) return colorRange.two;
      else if (country.pcr[year].Score === 3) return colorRange.three;
      else if (country.pcr[year].Score === 4) return colorRange.four;
      else if (country.pcr[year].Score === 5) return colorRange.five;
      else if (country.pcr[year].Score === 6) return colorRange.six;
    }
  } else if (mode === MapMode.MODE_TRANSPARENCY) {
    if (country.tran?.[year]) {
      if (country.tran[year].ti_score === 0) return colorRange.red;
      else if (country.tran[year].ti_score === 1) return colorRange.darkYellow;
      else if (country.tran[year].ti_score === 2) return colorRange.yellow;
      else if (country.tran[year].ti_score === 3) return colorRange.darkGreen;
      else if (country.tran[year].ti_score === 4) return colorRange.lightYellow;
      else if (country.tran[year].ti_score === 5) return colorRange.lightGreen;
      else if (country.tran[year].ti_score === 6) return colorRange.lightBlue;
    }
  } else {
    const countrySelected = list.includes(country.id);
    if (countrySelected) {
      return colorRange.lightBlue;
    }
  }
}

// Creates some custom content
export function generateIndexPopupContent(country, year) {
  return new CustomContent({
    outFields: ["*"],
    creator: (event) => {
      const docLen = country.dcr?.[year]?.documents?.length;
      const circles = new Array(11).fill("");
      return `
      <div>
      <p>Declared Capabilities Rating: ${country.dcr?.[year]?.Score ?? "-"}</p>
      <p>Perceived Capabilities Rating: ${country.pcr?.[year]?.Score ?? "-"}</p>
      <p>Transparency Index Rating: ${country.tran?.[year]?.label || "-"}</p>
      </div>
      <p>${country.dcr?.[year]?.GeneralTrends || "-"}</p>
      <P>Data Availability</P>
      <p> Rank:  ${country.dcr?.[year]?.Rank || "-"}</p>
      <p>Number of Documents: ${country.dcr?.[year]?.documents?.length}</p>
      <div class="circleList">
            <span class="rect rect-first"></span>
            <span class="line"></span>
${circles
          .map(
            (item, index) => `<span class="circle"
style="background: ${docLen === index + 1 ? "blue" : "darkgray"}"></span>`
          )
          .join("")}
            <span class="rect rect-middle"></span>
            <span class="rect rect-last"></span>
        </div>`;
    },
  });
}

export function generateIndexPopupContentP(country, year) {
  return new CustomContent({
    outFields: ["*"],
    creator: (event) => {
      const docLen = country.pcr[year].documents?.length;
      const circles = new Array(21).fill("");
      return `
      <div>
      <p>Declared Capabilities Rating: ${country.dcr?.[year]?.Score ?? "-"}</p>
      <p>Perceived Capabilities Rating: ${country.pcr?.[year]?.Score ?? "-"}</p>
      <p>Transparency Index Rating: ${country.tran?.[year]?.label || "-"}</p>
      </div>
      <p>${country.pcr?.[year]?.GeneralTrends || "-"}</p>
      <P>Data Availability</P>
      <p> Rank:  ${country.pcr?.[year]?.Rank || "-"}</p>
      <p>Number of documents:${country.pcr?.[year]?.documents?.length}</p>
      <div class="circleList-p">
            <span class="rect-p rect-first-p"></span>
            <span class="line-p-first"></span>
            <span class="line-p-second"></span>
${circles
          .map(
            (item, index) => `<span class="circle circle-p"
style="background: ${docLen === index + 1 ? "blue" : "darkgray"}"></span>`
          )
          .join("")}
            <span class="rect-p rect-middle-p"></span>
            <span class="rect-p rect-last-p"></span>
            <span class="rect-p rect-last-p"></span>
            <span class="rect-p rect-first-p-left"></span>
            <div class="circle circle-outline">
              <div class="circle-outline-gap">
                <div class="circle" style="background: ${docLen === 42 ? "blue" : "darkgray"
        }"></ ></div>
                <div class="circle" style="background: ${docLen === 57 ? "blue" : "darkgray"
        }"></></div>
              </div>
              <div class="circle-outline-gap">
               <div class="circle" style="background: ${docLen === 83 ? "blue" : "darkgray"
        }"></></div>
               <div class="circle" style="background: ${docLen === 95 ? "blue" : "darkgray"
        }"></></div>
              </div>
            </div>
        </div>`;
    },
  });
}
