import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Graphic from "@arcgis/core/Graphic";
import Print from "@arcgis/core/widgets/Print";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { FaPrint } from "react-icons/fa";
import data from "../../data/datawithtotallinks.json";
import { MapMode } from "../../model/mapMode.ts";
import {
  generateCountryColor,
  generateIndexPopupContent,
  generateIndexPopupContentP,
} from "./mapUtils.js";
import CountryLegend from "./components/legend/CountryLegend.js";
import Average from "./components/average/Average";
import LollipopChart from "../lollipopChart/LollipopChart";
import "./countries.css";
import DropdownMenu from "./components/dropdown/DropdownMenu";
import axios from "axios";
import { useMemo } from "react";
import TransparentContainer from "../../components/transparentContainer/TransparentContainer";
import one from '../../img/hcss.jpg'

const Countries = () => {
  const [showComponent, setShowComponent] = useState(true);
  const [mode, setMode] = useState(MapMode.MODE_TRANSPARENCY);
  const [currentYear, setCurrentYear] = useState(2022);
  const [list, setList] = useState([]);
  const [isDropdown, setIsDropdown] = useState(false);
  const mapViewRef = useRef(new MapView());
  const mapRef = useRef(new Map());
  const graphicLayer = useRef(new GraphicsLayer());
  const printWidgetRef = useRef();
  const [showPrintWidget, setShowPrintWidget] = useState(false);
  const [selectedLegend, setSelectedLegend] = useState();
  const [hoverMeta, setHoverMeta] = useState({
    position: undefined,
    title: "",
    docLen: 0,
  });

  const navigate = useNavigate();

  const lollipopChartData = data
    .filter((item) => {
      if (mode === MapMode.MODE_DECLARED) return item.dcr?.[currentYear];
      else if (mode === MapMode.MODE_PERCEIVED) return item.pcr?.[currentYear];
      else if (mode === MapMode.MODE_TRANSPARENCY)
        return item.tran?.[currentYear];
    })
    .map((item) => {
      if (mode === MapMode.MODE_DECLARED)
        return {
          Country: item.properties.NAME,
          Rank: item.dcr[currentYear].Rank,
          Score: item.dcr[currentYear].Score,
        };
      else if (mode === MapMode.MODE_PERCEIVED)
        return {
          Country: item.properties.NAME,
          Rank: item.pcr[currentYear].Rank,
          Score: item.pcr[currentYear].Score,
        };
      else if (mode === MapMode.MODE_TRANSPARENCY)
        return {
          Country: item.properties.NAME,
          Rank: item.tran[currentYear].ti_score,
          Score: item.tran[currentYear].label,
          ScoreDcr: item.tran[currentYear].score_dcr,
          ScorePcr: item.tran[currentYear].score_pcr,
        };
      else return [];
    });

  const toggleDrawer = () => {
    setIsDropdown(true);
  };

  const handleShowPrint = () => {
    if (!showPrintWidget) {
      setShowPrintWidget(true);
      mapViewRef.current.when(() => {
        // if print widget has not created yet
        if (!printWidgetRef.current) {
          printWidgetRef.current = new Print({
            view: mapViewRef.current,
            // specify your own print service
            printServiceUrl:
              "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
            templateOptions: {
              title: "HCSS The Hague Centre for Strategic Studies",
              author: "HCSS The Hague Centre for Strategic Studies",
              customTextElements: [
                {
                  // Add the <img> tag with the desired attributes
                  tagName: "img",
                  attributes: {
                    src: { one },
                    alt: "Image description",
                  },
                },
              ],
            },
            templates: [],
          });
        }
        // Add widget to the top right corner of the view
        mapViewRef.current.ui.add(printWidgetRef.current, "bottom-right");
      });
    } else {
      mapViewRef.current.ui.remove(printWidgetRef.current);
      setShowPrintWidget(false);
    }
  };

  useLayoutEffect(() => {
    mapRef.current = new Map({
      basemap: {
        portalItem: {
          id: "8d91bd39e873417ea21673e0fee87604", // nova basemap
        },
      },
    });

    mapViewRef.current = new MapView({
      container: "map",
      map: mapRef.current,
      center: [3, 35],
      navigationEnabled: false, // Disable navigation
      zoom: 1.5,
      constraints: {
        snapToZoom: false,
      },
    });

    graphicLayer.current = new GraphicsLayer();
    mapRef.current.add(graphicLayer.current);

    //pop out
    mapViewRef.current.on("pointer-move", function (event) {
      mapViewRef.current.hitTest(event).then(function (response) {
        if (response.results.length) {
          const graphic = response.results.filter(function (result) {
            // check if the graphic belongs to the layer of interest
            return result.graphic.layer === graphicLayer.current;
          })[0]?.graphic;
          if (graphic?.popupTemplate) {
            // Open the popup and display the tooltip
            // mapViewRef.current.popup.open({
            //   features: [graphic],
            //   location: graphic.geometry,
            // });

            setHoverMeta({
              position: [event.x, event.y],
              title: graphic.popupTemplate.title,
            });
          } else {
            setHoverMeta();
            // popup.close();
            // mapViewRef.current.popup.close();
          }
        } else {
          mapViewRef.current.popup.close();
        }
      });
    });

    loadPolygon();
  }, []);

  useEffect(() => {
    mapViewRef.current
      .when(function () {
        // Event handler that fires each time an action is clicked
        if (!mapViewRef.current.popup.on) return;
        mapViewRef.current.popup.on("trigger-action", function (event) {
          const eventId = event.action.id;
          if (eventId.startsWith("action-view_")) {
            const country = eventId.split("_")[1];
            navigate("/detail/" + country + "?mode=" + mode);
          } else if (eventId.startsWith("action-add_")) {
            const countryId = parseInt(eventId.split("_")[1]);
            setIsDropdown(true);
            setList((list) => {
              if (!list.includes(countryId)) return [...list, countryId];
              else return list;
            });
          } else if (eventId.startsWith("action-past_")) {
            const countryId = parseInt(eventId.split("_")[1]);
            navigate("/pastData/" + countryId);
          }
        });
      })
      .then(() => { });
  }, [mode]);

  useEffect(() => {
    if (mapRef.current && mapViewRef.current) {
      loadPolygon();
    }
  }, [mode, list, currentYear, selectedLegend]);

  useEffect(() => {
    setSelectedLegend();
  }, [mode]);

  const loadPolygon = () => {
    graphicLayer.current.removeAll();

    data
      .filter((item) => {
        if (mode === MapMode.MODE_DECLARED && item.dcr?.[currentYear]) {
          if (selectedLegend === undefined) return true;
          return item.dcr[currentYear].Score === selectedLegend;
        }
        if (mode === MapMode.MODE_PERCEIVED && item.pcr?.[currentYear]) {
          if (selectedLegend === undefined) return true;
          return item.pcr[currentYear].Score === selectedLegend;
        }
        if (mode === MapMode.MODE_TRANSPARENCY && item.tran?.[currentYear]) {
          if (selectedLegend === undefined) return true;
          return item.tran[currentYear].ti_score === selectedLegend;
        }
        return true;
      })
      .forEach((country) => {
        country.geometry.coordinates.forEach((provincePart, index) => {
          const polygon = {
            type: "polygon",
            rings: provincePart,
          };

          const simpleFillSymbol = {
            type: "simple-fill",
            color: generateCountryColor(country, mode, list, currentYear),
            outline: {
              color: [0, 100, 200],
              width: 1,
            },
          };

          const viewAction = {
            title: "Documents",
            id: "action-view_" + country.properties.NAME,
            className: "esri-icon-table",
          };

          const generatePopupTemplateData = () => {
            const actions =
              country.dcr?.[currentYear] || country.pcr?.[currentYear]
                ? [addAction]
                : [];

            if (mode === MapMode.MODE_DECLARED && country.dcr?.[currentYear]) {
              return {
                title: `${country.properties.NAME_LONG}`,
                content: [generateIndexPopupContent(country, currentYear)],
                actions: [viewAction, ...actions],
              };
            } else if (
              mode === MapMode.MODE_PERCEIVED &&
              country.pcr?.[currentYear]
            ) {
              return {
                title: `${country.properties.NAME_LONG}`,
                content: [generateIndexPopupContentP(country, currentYear)],
                actions: [viewAction, ...actions],
              };
            } else if (
              mode === MapMode.MODE_TRANSPARENCY &&
              country.tran?.[currentYear]
            ) {
              return {
                title: `${country.properties.NAME_LONG}`,
                content: `
              <p>Declared Capabilities Rating: ${country.tran[currentYear].score_dcr
                  }</p>
              <p>Perceived Capabilities Rating:${country.tran[currentYear].score_pcr
                  }</p>
              <p>Transparency Index Rating: ${country.tran[currentYear].label
                  }</p>
              </br>
              <p>${country.tran[currentYear].OveralTrend || ""}</p><br/>
              `,
                actions: [...actions],
              };
            }
          };

          const addAction = {
            title: "Filter",
            id: "action-add_" + country.id,
            className: "esri-icon-plus",
          };

          const attributes = {
            Name: "Information about the country:",
          };

          const polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
            attributes: attributes,
            popupTemplate: generatePopupTemplateData(),
          });
          graphicLayer.current.add(polygonGraphic);
        });
      });
  };

  const { docLen, score } = useMemo(() => {
    const country = data.find(
      (item) => item.properties.NAME_LONG === hoverMeta?.title
    );

    if (mode === MapMode.MODE_DECLARED)
      return {
        docLen: country?.dcr?.[currentYear]?.documents.length || "",
        score: country?.dcr?.[currentYear]?.Score || "0",
      };
    else if (mode === MapMode.MODE_PERCEIVED)
      return {
        docLen: country?.pcr?.[currentYear]?.documents.length || "",
        score: country?.pcr?.[currentYear]?.Score || "0",
      };

    return "";
  }, [mode, hoverMeta?.title, currentYear]);

  const handleClosePopOut = () => {
    if (hoverMeta?.position) setHoverMeta();
  };

  return (
    <div>
      {showComponent && (
        <TransparentContainer setShowComponent={setShowComponent} />
      )}
      <section
        className="country-container"
        onMouseOver={handleClosePopOut}
        style={{ filter: showComponent && "blur(10px)" }}
      >
        <div className="country-container-btn">
          <button
            className={
              mode === MapMode.MODE_DECLARED
                ? "btn map-btn active"
                : "btn map-btn"
            }
            onClick={() => {
              setMode(MapMode.MODE_DECLARED);
            }}
          >
            Declared Capabilities
          </button>
          <button
            className={
              mode === MapMode.MODE_PERCEIVED
                ? "btn map-btn active"
                : "btn map-btn"
            }
            onClick={() => setMode(MapMode.MODE_PERCEIVED)}
          >
            Perceived Capabilities
          </button>
          <button
            className={
              mode === MapMode.MODE_TRANSPARENCY
                ? "btn map-btn active"
                : "btn map-btn"
            }
            onClick={() => setMode(MapMode.MODE_TRANSPARENCY)}
          >
            Transparency Score
          </button>
          <div className="">
            <label>
              <select
                className="btn-select-year"
                value={currentYear}
                onChange={(e) => setCurrentYear(e.target.value)}
              >
                <option value={2022}>Filter by year 2022</option>
                <option value={2023}>Filter by year 2023</option>
              </select>
            </label>
            <button className="btn" onClick={toggleDrawer}>
              Filter by country
            </button>
          </div>
        </div>
        <div className={"country-container-map"}>
          <Average currentYear={currentYear} />
          <LollipopChart mode={mode} lollipopChartData={lollipopChartData} />
          <div className="map-container">
            <div id="map" className="map-view"></div>
            <CountryLegend
              mode={mode}
              year={currentYear}
              selectedLegend={selectedLegend}
              setSelectedLegend={setSelectedLegend}
            />
            <button className="country-print-btn" onClick={handleShowPrint}>
              <FaPrint /> {showPrintWidget ? "Close" : "Download the map"}
            </button>
            {hoverMeta?.position && (
              <div
                className="popup-hover"
                style={{
                  left: hoverMeta.position[0] - 3,
                  top: hoverMeta.position[1] - 3,
                }}
              >
                <div className="selected-title">{hoverMeta.title}</div>
                <div> {docLen ? `Documents: ${docLen}` : ""}</div>
                <div> {score ? `Score:     ${score}` : ""}</div>
              </div>
            )}
          </div>
        </div>
        <DropdownMenu
          data={data}
          list={list}
          setList={setList}
          isDropdown={isDropdown}
          setIsDropdown={setIsDropdown}
          year={currentYear}
        />
      </section>
    </div>
  );
};

export default Countries;
